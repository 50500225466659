import xtour_logo from "../assets/image/XTOUR-Logo-icon-transparent.png";

// TODO update apiDomain and site_url
// PRODUCTION
// const apiDomain = 'https://api.xtour.com.au';
// const siteUrl = 'https://xqm.xtour.com.au';

// LOCAL
// const apiDomain = "http://127.0.0.1:8000";
// const siteUrl = "http://localhost:3000";

// STAGINGG
const apiDomain = "https://xtourapi.notarek.com";
const siteUrl = "https://xtour.notarek.com";

const themeconfig = {
  site_url: siteUrl,
  Google_reCAPTCHA: "6LefOlEoAAAAAGg2w7TjBYcleqkclQvDrj-l4bWF",
  APILinks: {
    Login: `${apiDomain}/login`,
    forgot_password: `${apiDomain}/forgotpassword`,
    reset_password: `${apiDomain}/resetpassword`,
    authactiveuser: `${apiDomain}/api/v1/user/token`,
    get_client_data: `${apiDomain}/api/v1/getclients`,
    add_project: `${apiDomain}/api/v1/project/add`,
    get_all_user: `${apiDomain}/api/v1/user/list?id=`,
    project_list: `${apiDomain}/api/v1/project/list`,
    get_all_clients: `${apiDomain}/api/v1/user/list`,
    update_user: `${apiDomain}/api/v1/user/update`,
    delete_project: `${apiDomain}/api/v1/project/delete`,
    add_user: `${apiDomain}/api/v1/user/add`,
    delete_clients: `${apiDomain}/api/v1/user/delete`,
    user_update: `${apiDomain}/api/v1/user/update`,
    get_all_project: `${apiDomain}/api/v1/project/list`,
    delete_embed_url: `${apiDomain}/api/v1/embed/delete`,
    update_project: `${apiDomain}/api/v1/project/update`,
    get_logs_list: `${apiDomain}/api/v1/logs/list`,
    get_project_defualt_data: `${apiDomain}/api/v1/getProject`,
    user_list: `${apiDomain}/api/v1/user/list`,
    delete_user: `${apiDomain}/api/v1/user/delete`,
    help_p_bookling_add: `${apiDomain}/api/v1/help/add`,
    get_faq: `${apiDomain}/api/v1/faq/list`,
    add_feedback: `${apiDomain}/api/v1/feedback/add`,
    add_notes: `${apiDomain}/api/v1/notes/add`,
    list_notes: `${apiDomain}/api/v1/notes/list`,
    analytics_add: `${apiDomain}/api/v1/analytics/addOrUpdate`,
    notes_delete: `${apiDomain}/api/v1/notes/delete`,
    add_attribute: `${apiDomain}/api/v1/attribute/add`,
    list_attribute: `${apiDomain}/api/v1/attribute/list`,
    delete_attribute: `${apiDomain}/api/v1/attribute/delete`,
    analytics_list: `${apiDomain}/api/v1/analytics/list`,
    defult_folder_select: `${apiDomain}/api/v1/getAttributes`,
    document_add: `${apiDomain}/api/v1/document/add`,
    all_list_document: `${apiDomain}/api/v1/document/list`,
    delete_document: `${apiDomain}/api/v1/document/delete`,
    zip_download: `${apiDomain}/space/public/api/download_zip_file_to_space`,
    change_status_folder: `${apiDomain}/api/v1/attribute/status`,
    check_folder_key: `${apiDomain}/api/v1/attribute/check-key`,
    check_doc_key: `${apiDomain}/api/v1/document/check-key`,
    change_status_doc: `${apiDomain}/api/v1/document/status`,
    // add_faq: `${apiDomain}/api/v1/faq/add`,
    // delete_faq: `${apiDomain}/api/v1/faq/delete`,
    // faq_get_by_id: `${apiDomain}/api/v1/faq/list`,
    // update_faq: `${apiDomain}/api/v1/faq/update`,
    download_pdf: `${apiDomain}/space/public/api/download_file_to_space_image`,
    download_photo: `${apiDomain}/space/public/api/download_file_to_space_image`,
    change_password: `${apiDomain}/api/v1/user/change-passwor`,
  },
  image: {
    xtour_image: xtour_logo,
    user_dummy: "https://portal.xtour.com.au/img/undraw_profile.svg",
  },
  chart: {
    chart_month: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  },
  sidebar_data: {
    sidebar: [
      {
        active_role: 0,
        menu_array: [
          {
            label: "Dashboard",
            slug: "dashboard",
            componentName: "Dashboard",
            type: 0,
          },
          {
            label: "Projects",
            slug: "projects-admin",
            componentName: "Projects",
            type: 0,
          },
          {
            label: "Clients",
            slug: "clients",
            componentName: "Clients",
            type: 0,
          },
          {
            label: "Profile",
            slug: "profile",
            componentName: "Profile",
            type: 0,
          },
          {
            label: "Project Booking",
            slug: "project-booking",
            componentName: "ProjectBooking",
            type: 1,
          },
          {
            label: "Add Project",
            slug: "projects-admin/add-project",
            componentName: "AddProject",
            type: 1,
          },
          {
            label: "Add Clients",
            slug: "clients/add-clients",
            componentName: "AddClients",
            type: 1,
          },
          {
            label: "Update Clients",
            slug: "clients/edit-clients",
            componentName: "UpdateClients",
            type: 1,
          },
          {
            label: "Update Project",
            slug: "projects-admin/edit-project",
            componentName: "UpdateProject",
            type: 1,
          },
          {
            label: "Folder",
            slug: "folder/documents/:project_id",
            componentName: "Folder",
            type: 1,
          },
          {
            label: "USER",
            slug: "clients/:user_id",
            componentName: "AdminUserView",
            type: 1,
          },
          {
            label: "USER",
            slug: "clients/:user_id/add-user",
            componentName: "AddUserAdmin",
            type: 1,
          },
          {
            label: "USER",
            slug: "clients/edit-user/:user_id",
            componentName: "AdminUserEdit",
            type: 1,
          },
        ],
      },
      {
        active_role: 1,
        menu_array: [
          {
            label: "Dashboard",
            slug: "dashboard",
            componentName: "Dashboard",
            type: 0,
          },
          {
            label: "Projects",
            slug: "projects",
            componentName: "Project_UC",
            type: 0,
          },
          {
            label: "Users",
            slug: "users",
            componentName: "Users",
            type: 0,
          },
          {
            label: " Help / Support",
            slug: "help-support",
            componentName: "Help",
            type: 0,
          },
          {
            label: "Profile",
            slug: "client-profile",
            componentName: "ClientProfile",
            type: 0,
          },
          {
            label: "Project Booking",
            slug: "project-booking",
            componentName: "ProjectBooking",
            type: 0,
          },
          {
            label: "Add User",
            slug: "users/add-user",
            componentName: "AddUser",
            type: 1,
          },
          {
            label: "Update User",
            slug: "users/edit-user",
            componentName: "UpdateUser",
            type: 1,
          },
          {
            label: "FAQ",
            slug: "help-support/support-faqs",
            componentName: "FAQ",
            type: 1,
          },
          {
            label: "ProjectSingle",
            slug: "projects-single/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Model",
            slug: "projects-single/model/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Documents",
            slug: "projects-single/documents/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Photos",
            slug: "projects-single/photos/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Notes",
            slug: "projects-single/notes/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Feedback",
            slug: "projects-single/feedback/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Statistics",
            slug: "projects-single/statistics/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Knowledgebase",
            slug: "help-support/knowledgebase",
            componentName: "Knowledgebase",
            type: 1,
          },
          {
            label: "PointCloud",
            slug: "point-cloud/:project_id",
            componentName: "PointCloudData",
            type: 1,
          },
        ],
      },
      {
        active_role: 2,
        menu_array: [
          {
            label: "Dashboard",
            slug: "dashboard",
            componentName: "Dashboard",
            type: 0,
          },
          {
            label: "Projects",
            slug: "projects",
            componentName: "Project_UC",
            type: 0,
          },
          {
            label: " Help / Support",
            slug: "help-support",
            componentName: "Help",
            type: 0,
          },
          {
            label: "FAQ",
            slug: "help-support/support-faqs",
            componentName: "FAQ",
            type: 1,
          },
          {
            label: "Profile",
            slug: "profile",
            componentName: "Profile",
            type: 0,
          },
          {
            label: "Project Booking",
            slug: "project-booking",
            componentName: "ProjectBooking",
            type: 0,
          },
          {
            label: "ProjectSingle",
            slug: "projects-single/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Model",
            slug: "projects-single/model/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Documents",
            slug: "projects-single/documents/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Photos",
            slug: "projects-single/photos/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Notes",
            slug: "projects-single/notes/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Feedback",
            slug: "projects-single/feedback/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Statistics",
            slug: "projects-single/statistics/:id",
            componentName: "ProjectSingle",
            type: 1,
          },
          {
            label: "Knowledgebase",
            slug: "help-support/knowledgebase",
            componentName: "Knowledgebase",
            type: 1,
          },
          {
            label: "PointCloud",
            slug: "point-cloud/:project_id",
            componentName: "PointCloudData",
            type: 1,
          },
        ],
      },
    ],
  },
};

export default themeconfig;
